import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints';

const H2 = styled.h2`
  color: #414141;
  font-size: 20px;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: fit-content;

  p {
    margin: 0 0 3px;
    padding: 0 0 15px;
    display: inline-block;
    position: relative;
  }

  :before {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    background-color: #e5e5e5;
    bottom: 10px;
  }

  :after {
    position: absolute;
    content: '';
    height: 2px;
    width: 40px;
    background-color: #77d7c9;
    bottom: 10px;
  }

  .manager-label {
    color: #808080;
    font-weight: normal;
  }

  .worker-label {
    font-size: 18px;
    color: #ffffff;
    width: 160px;
    height: 25px;
    padding: 3px 0 25px;
    margin-top: -4px;
    margin-left: 15px;
    text-align: center;
    border-radius: 2px;
    background-color: #e2a679;
  }

  ${BreakPoints.medium} {
    flex-direction: column;

    p {
      display: block;
      margin: 0;
    }

    .manager-label {
      margin: -10px 0 20px;
      font-size: 14px;
    }

    .worker-label {
      margin: -10px 0 20px;
    }
  }
`

type Props = {
    /** クラス名 */
    className?: string
    /** タイトル */
    label: string
    /** 「○○向け機能説明」 */
    isManager?: boolean
    /** 「○○向け機能説明」内文言 */
    managerLabel?: string
    /** 従業員用ラベル */
    isWorker?: boolean
    /** 従業員用ラベル文言 */
    workerLabel?: string
}

/**
 * コラム用共通H2コンポーネント
 * @param props
 * @constructor
 */
const helpMovieH2 = (props: Props): JSX.Element => {
    const {className, isWorker, isManager = ''} = props

    return (
        <H2 className={className}>
            <p>{props.label}</p>
            {isManager && <span className="manager-label">{props.managerLabel}</span>}
            {isWorker && <span className="worker-label">{props.workerLabel}</span>}
        </H2>
    )
}

export default helpMovieH2
