import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import CommonH2 from '../../components/atoms/commonH2'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import Layout from '../../components/organisms/layout'
import BreakPoints from '../../styles/breakPoints'
import { CategoryKind } from '../../types/CategoryKind'
import HelpMovieH2 from '../../components/atoms/helpMovieH2'
import ManualBtn from '../../components/molecules/manualBtn'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  max-width: 800px;
  margin: 40px auto 0 auto;

  .card {
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }

  .flex {
    .worker-label {
      background-color: #dd9593;
    }
  }

  .fixed {
    .worker-label {
      background-color: #166fc7;
    }
  }

  .detail-wrap {
    margin-left: 25px;
  }

  ${BreakPoints.medium} {
    width: 90%;
  }
`

type IProps = PageProps<GatsbyTypes.ManagePageQuery>

/**
 * 動画マニュアルページ
 * @constructor
 */
const MoviePage = ({ location }: IProps) => {
  const paths: ITopicPath[] = [
    { title: 'TOP', link: useRouteParam('/help') },
    { title: '動画マニュアル' },
  ]
  return (
    <Layout location={location} paths={paths} categoryKind={CategoryKind.MOVIE}>
      <SEO title="動画マニュアル" />
      <CommonH2 label="動画マニュアル" />
      <p className="txt-center mt-020 mb-020">
        キンクラの操作方法や利用手順を動画で分かりやすくご案内しています。
      </p>
      <Wrapper className="wrap">
        <HelpMovieH2
          label="初期セットアップ手順"
          isManager
          managerLabel="（システム管理者向け機能説明）"
        />
        <div className="card">
          <iframe
            width="200"
            height="115"
            src="https://www.youtube.com/embed/7nnKf-Ka1sc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          <div className="detail-wrap">
            <p>
              システム管理者向けの動画マニュアルです。
              <br />
              「社員情報」の登録や編集、確認手順、権限について解説しています。
            </p>
          </div>
        </div>
        <HelpMovieH2
          label="申請の承認と残業/有休の管理方法"
          isManager
          managerLabel="（管理職向け機能説明）"
        />
        <div className="card">
          <iframe
            width="200"
            height="115"
            src="https://www.youtube.com/embed/_IUqPn08f7M"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          <div className="detail-wrap">
            <p>
              管理職向けの動画マニュアルです。
              <br />
              申請内容の確認や承認方法、残業・有休の管理方法など、管理職者がキンクラ上でできることについて解説しています。
            </p>
          </div>
        </div>
        <HelpMovieH2
          label="キンクラの基本的な利用方法"
          isWorker
          workerLabel="変形労働時間制"
        />
        <div className="card">
          <iframe
            width="200"
            height="115"
            src="https://www.youtube.com/embed/c2GWjxY18Kc"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          <div className="detail-wrap">
            <p>
              「変形労働時間制」の従業員向けの動画マニュアルです。
              <br />
              シフト・打刻・勤務表提出など、勤怠に関わる基本的な操作方法を解説しています。
            </p>
          </div>
        </div>
        <HelpMovieH2
          className="flex"
          label="キンクラの基本的な利用方法"
          isWorker
          workerLabel="フレックス時間制"
        />
        <div className="card">
          <iframe
            width="200"
            height="115"
            src="https://www.youtube.com/embed/oaCV4pCT9qM"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          <div className="detail-wrap">
            <p>
              「フレックスタイム制」の従業員向けの動画マニュアルです。
              <br />
              打刻・勤務表提出など、勤怠に関わる基本的な操作方法を解説しています。
            </p>
          </div>
        </div>
        <HelpMovieH2
          className="fixed"
          label="キンクラの基本的な利用方法"
          isWorker
          workerLabel="固定労働時間制"
        />
        <div className="card">
          <iframe
            width="200"
            height="115"
            src="https://www.youtube.com/embed/mJVVWigQSDY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
          <div className="detail-wrap">
            <p>
              「固定労働時間制」の従業員向けの動画マニュアルです。
              <br />
              打刻・勤務表提出・シフト確認など、勤怠に関わる基本的な操作方法を解説しています。
            </p>
          </div>
        </div>
      </Wrapper>
      <ManualBtn />
    </Layout>
  )
}

export default MoviePage

export const pageQuery = graphql`
  query MoviePage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
